import request from '@/plugins/axios';

/**
 * 获取一至四级地址
 */
export function getAddress(params) {
  return request({
    url: 'shop/areaInfo/list',
    method: 'get',
    params
  });
}

/**
 * 地址新增
 */
export function addAddress(data) {
  return request({
    url: 'shop-mobile/userAddress',
    method: 'post',
    data
  });
}

/**
 * 地址修改
 */
export function editAddress(data) {
  return request({
    url: 'shop-mobile/userAddress',
    method: 'put',
    data
  });
}

/**
 * 修改默认地址
 */
export function editDefaultAddress(data) {
  return request({
    url: `shop-mobile/userAddress/updateDefault/${data.id}`,
    method: 'put',
    data
  });
}

/**
 * 地址查询
 */
export function queryUserAddressByPage(params) {
  return request({
    url: 'shop-mobile/userAddress/page',
    method: 'get',
    params
  });
}

/**
 * 地址id查询
 */
export function queryUserAddressById(params) {
  return request({
    url: `shop-mobile/userAddress/${params.id}`,
    method: 'get',
    params
  });
}

/**
 * 地址删除
 */
export function deleteAddress(params) {
  return request({
    url: `shop-mobile/userAddress/${params.id}`,
    method: 'delete',
    params
  });
}
