import { wxPayUnifiedOrder } from '@/api/system';
import { pcQrCodeToWxPay, userBalancePayment, normalOrderPayAgain } from '@/api/normalOrder';
import { integralPayment, integralPaymentAgain } from '@/api/integralGoods';
import { payOrder , payAgain2 } from '@/api/shoppingCart'
const serviceMap = {
  userBalancePayment,
  normalOrderPayAgain,
  pcQrCodeToWxPay,
  integralPayment,
  integralPaymentAgain,
  payOrder,
  payAgain2
};

export function wxJsPay(order, serviceName = 'userBalancePayment') {

  return new Promise(resolve => {
    function onBridgeReady() {

      let service = serviceMap[serviceName];

      service(order).then(res => {
        if (!res)
          return resolve(false);

        let { appId, timeStamp, nonceStr, signType, paySign } = res || {};

        // alert(JSON.stringify(res));

        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            appId, //: 'wx2421b1c4370ec43b',     //公众号ID，由商户传入
            timeStamp, //: '1395712654',         //时间戳，自1970年以来的秒数
            nonceStr, //: 'e61463f8efa94090b1f366cccfbbb444', //随机串
            package: res.package, //: 'prepay_id=u802345jgfjsdfgsdg888',
            signType, //: 'MD5',         //微信签名方式：
            paySign, //: '70EA570631E4BB79628FBCA90534C63FF7FADD89' //微信签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              resolve(true);
            } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
              // 支付过程中用户取消
              // alert(res.err_msg);
              resolve(false);

            } else {
              // 支付失败
              alert(JSON.stringify(res));
              resolve(false);
            }
          });

      });

    }

    if (typeof WeixinJSBridge == 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
      }
    } else {
      onBridgeReady();
    }

  });


}


export function depositOrderWxPay(wxPayOpts = {}) {

  let { appId, timeStamp, nonceStr, signType, paySign } = wxPayOpts || {};

  return new Promise(resolve => {

    function onBridgeReady() {

      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          appId, //: 'wx2421b1c4370ec43b',     //公众号ID，由商户传入
          timeStamp, //: '1395712654',         //时间戳，自1970年以来的秒数
          nonceStr, //: 'e61463f8efa94090b1f366cccfbbb444', //随机串
          package: wxPayOpts.package, //: 'prepay_id=u802345jgfjsdfgsdg888',
          signType, //: 'MD5',         //微信签名方式：
          paySign, //: '70EA570631E4BB79628FBCA90534C63FF7FADD89' //微信签名
        },
        function (res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            resolve(true);
          } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
            // 支付过程中用户取消
            // alert(res.err_msg);
            resolve(false);

          } else {
            // 支付失败
            alert(JSON.stringify(res));
            resolve(false);
          }
        });

    }

    if (typeof WeixinJSBridge == 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
      }
    } else {
      onBridgeReady();
    }
  });
}
