<template>
  <div
      class="pay-address-container"
      @click="handlePayAddressClick"
  >
    <div class="address-info-container">
      <div class="left-icon">
        <van-icon name="location-o"/>
      </div>

      <div v-show="!loading && addressData && addressData.id">
        <div class="user-info-container">
          <div class="user-info">
            {{ addressData.name }} <span>{{ addressData.mobile }}</span>
          </div>
          <div
              v-if="isDefaultAddress(addressData)"
              class="default-tag"
          >
            <van-tag plain type="primary">默认</van-tag>
          </div>
        </div>
        <div class="user-address-info">
          {{ formatAddressText(addressData) }}
        </div>
      </div>
      <div class="no-address" v-if="!loading && (!addressData || !addressData.id)">
        无收货地址，<span>去添加</span>
      </div>

      <div class="right-icon">
        <van-icon name="arrow"/>
      </div>

    </div>

  </div>
</template>

<script>
import { queryUserAddressById, queryUserAddressByPage } from '@/api/address';
import { mapState } from 'vuex';
import { objectGetter } from '@/util';

export default {
  name: 'payAddress',
  props: {
    value: {
      default: () => ({}),
      type: Object,
    },
    loading: {
      default: () => true,
    }
  },
  computed: {
    ...mapState({
      addressId: state => state.payment.addressId,
      refreshState: state => state.payment.refreshAddressState,
    }),
    addressData: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    onLoading: {
      get() {
        return this.loading;
      },
      set(val) {
        this.$emit('update:loading', val);
      },
    },
  },
  created() {
    this.$watch(() => [this.addressId, this.refreshState], async () => {
      this.addressData = {};
      if (this.addressId) {
        this.onLoading = true;
        queryUserAddressById({
          id: this.addressId
        }).then(res => {
          this.onLoading = false;
          this.addressData = res || {};
          this.$emit('change', { ...res });
        });
      } else {
        this.onLoading = true;

        this.queryDefaultAddress().then(defData => {
          if (defData) {
            this.onLoading = false;
            this.addressData = defData;
            this.$emit('change', { ...defData });
          } else {
            this.queryFirstAddress().then(firstData => {
              this.onLoading = false;
              this.addressData = firstData;
              this.$emit('change', { ...firstData });
            })
          }
        })
        // queryUserAddressByPage({
        //   defaultAddress: 0
        // }).then(res => {
        //   this.onLoading = false;
        //   let data = objectGetter(res, 'records.0') || {};
        //   this.addressData = data;
        //   this.$emit('change', { ...data });
        // });
      }
    }, { immediate: true, deep: true });
  },
  methods: {
    queryDefaultAddress() {
      return new Promise(resolve => {
        queryUserAddressByPage({
          defaultAddress: 0
        }).then(res => {
          let data = objectGetter(res, 'records.0');
          resolve(data || undefined);
        });
      });
    },
    queryFirstAddress() {
      return new Promise(resolve => {
        queryUserAddressByPage().then(res => {
          let data = objectGetter(res, 'records.0');
          resolve(data || {});
        });
      });
    },
    handlePayAddressClick() {
      this.$store.commit('receiverAddress/setRefreshing', true);
      this.$router.push({
        path: '/addressList',
        query: {
          model: 'check',
        },
      });
    },
    isDefaultAddress(obj) {
      return obj.defaultAddress === 0;
    },
    formatAddressText(obj) {
      let addressKeys = ['provice', 'city', 'country', 'street', 'address'];
      let addressArr = [];
      addressKeys.forEach(key => {
        let itemStr = obj[key];
        itemStr && addressArr.push(itemStr);
      });
      return addressArr.join(' ');
    },
  }
};
</script>

<style lang="less" scoped>
.pay-address-container {

  .address-info-container {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding: 20px 40px 23px 60px;
    min-height: 93px;
    background: url("../../img/categories/dashed.png") center bottom no-repeat #fff;
    background-size: 100% 3px;

    .user-info-container {
      display: flex;

      .user-info {
        flex: 1;
        line-height: 24px;
        font-size: 16px;
        color: #000;

        & > span {
          display: inline-block;
          font-size: 14px;
          color: #666;
          font-weight: normal;
        }
      }

      .default-tag {
        flex-shrink: 0;
        width: 40px;

        .van-tag {
          margin-top: 2px;
          line-height: 18px;
        }
      }
    }

    .user-address-info {
      margin-top: 5px;
      line-height: 20px;
      font-size: 14px;
      color: #333;
    }

    .left-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      width: 60px;

      .van-icon {
        font-size: 26px;
        color: @primary-color;
      }
    }


    .right-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      width: 40px;

      .van-icon {
        font-size: 22px;
        color: #999;
      }
    }

    .no-address {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      font-size: 14px;
      color: #333;

      & > span {
        color: @primary-color;
      }
    }
  }

}
</style>
