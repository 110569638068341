<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <page-container class="payment-container nav-page-container">
      <div class="payment-card-item">
        <pay-address
            v-model="addressInfo"
            :loading.sync="addressLoading"
            @change="handleAddressChange"
        />
      </div>

      <div class="payment-card-item goods-info-container">
        <!--      <div class="agency-container">-->
        <!--        <van-icon name="shop-o"/>-->
        <!--        {{ orderInfo.agencyName }}-->
        <!--      </div>-->
        <goods-info
            view-model="small"
            :value="{
              goodsImgSrc: getFirstImg(orderInfo),
              goodsName: orderInfo.name,
              goodsDesc: orderInfo.subName,
          }"
        >
          <template #price>
            <div v-html="priceAndIntegralText"></div>
          </template>

          <template #price-right>
            <van-stepper v-model="form.num" theme="round" disable-input/>
          </template>
        </goods-info>
      </div>

      <div class="payment-card-item">
        <van-field
            v-model="form.memo"
            type="textarea"
            rows="1"
            :autosize="true"
            :show-word-limit="true"
            maxlength="100"
            label-width="2.5em"
            label="留言"
            placeholder="请输入留言"
        />
      </div>

      <div class="payment-card-item pay-way-container">
        <van-radio-group v-model="form.payWay">
          <van-cell-group>
            <van-cell
                v-for="pay of payWayList"
                v-show="pay.type === form.payWay"
                :title="pay.text"
                clickable
            >
              <!--            @click="form.payWay = pay.type"-->
              <template #title>
                <van-icon
                    :class="pay.iconName"
                    :name="pay.iconName"
                />
                {{ pay.text }}
                <!--              <span-->
                <!--                  v-if="pay.type === '04'"-->
                <!--                  class="user-balance-display-container"-->
                <!--              >-->
                <!--                ￥<span>{{ userBalance }}</span>-->
                <!--              </span>-->
              </template>
              <template #right-icon>
                <van-radio :name="pay.type"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>

      <div class="payment-bottom-bar">
        <div class="total-container">
          <span>应付：</span>
          <div class="price-container">
            <div class="total-price">
              <span v-if="displayAllPrice.goodsPrice" class="integral-price">{{ displayAllPrice.goodsPrice }}</span>
              <span v-if="displayAllPrice.integralUsed" class="integral">{{ displayAllPrice.integralUsed }}</span>
            </div>
            <div
                v-show="form.carriage"
                class="carriage-price"
            >
              (邮费：<span>{{ formatPrice(form.carriage * form.num) }}</span>)
            </div>
          </div>
        </div>
        <div class="buy-container">
          <van-button
              round
              type="primary"
              style="width: 100%"
              @click="saveOrder"
          >提交订单
          </van-button>
        </div>
      </div>
    </page-container>
  </div>
</template>

<script>
import PayAddress from '@/views/payment/payAddress';
import { mapState } from 'vuex';
import { getPayIntegralGoodsInfo, integralSaveOrder, integralPayment } from '@/api/integralGoods';
import GoodsInfo from '@/components/GoodsInfo';
import { objectGetter } from '@/util';
import { Dialog, Toast } from 'vant';
import { wxJsPay } from '@/util/wxJsPay';
import { userBalancePayment } from '@/api/normalOrder';
import { DAI_FU_KUAN } from '@/const/orderStatus';

/*
        { label: '微信支付', value: '01' },
        { label: '支付宝支付', value: '02' },
        { label: '银联支付', value: '03' },
        { label: '余额支付', value: '04' },
        { label: '积分支付', value: '05' },
        { label: '微信加积分支付', value: '06' },
        { label: '支付宝加积分', value: '07' },
        { label: '银联加积分', value: '08' },
        { label: '余额加积分', value: '09' },
 */

export default {
  name: 'integralPayment',
  components: { GoodsInfo, PayAddress },
  data() {
    return {
      integralGoodsId: '',

      addressLoading: true,
      loading: true,

      addressInfo: {},
      orderInfo: {},
      // goodsInfo: {},

      form: {
        carriage: 0, // 邮费
        num: 1, // 数量
        memo: '', // 留言
        payWay: '', // 支付方式
      },

      userBalance: '', // 用户余额
      // 支付方式数据
      payWayList: [
        {
          iconName: 'points',
          text: '积分支付',
          type: '05',
        },
        {
          iconName: 'wechat',
          text: '微信支付',
          type: '01',
        },
        {
          iconName: 'gold-coin-o',
          text: '微信加积分支付',
          type: '06',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.integralPayment.refreshing,
    }),
    // 邮费text
    carriageText() {
      let { carriage } = this.form;
      return carriage ? `(含邮费：<span>${carriage}</span>)` : '';
    },
    // 是否需要金钱支付
    isNeedMoneyToPay() {
      let { goodsPrice } = this.orderInfo;
      let { carriage } = this.form;
      return !!(+goodsPrice || +carriage);
    },
    priceAndIntegralText() {
      let { goodsPrice, integralUsed } = this.orderInfo || {};
      let priceHtml = goodsPrice ? [`<span class="integral-price">${goodsPrice}</span>`] : [];
      let integralUsedHtml = integralUsed ? [`<span class="integral">${integralUsed}</span>`] : [];
      return [...priceHtml, ...integralUsedHtml].join(' + ');
    },
    // 总价
    displayAllPrice() {
      let { goodsPrice, integralUsed } = this.orderInfo;
      let { num, carriage } = this.form;
      goodsPrice = this.formatPrice(goodsPrice * num + carriage * num);
      integralUsed = this.formatPrice(integralUsed * num);
      return {
        goodsPrice,
        integralUsed,
        carriage,
      };
    },
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      Object.assign(this, {
        integralGoodsId: '',
        addressLoading: true,
        loading: true,
        addressInfo: {},
        orderInfo: {},
        form: {
          carriage: 0, // 邮费
          num: 1, // 数量
          memo: '', // 留言
          payWay: '', // 支付方式
        },
        userBalance: '', // 用户余额
      });
      this.$store.commit('integralPayment/setRefreshing', false);
      this.$store.commit('payment/setAddressId', null);
      this.init();
    }, { immediate: true });
  },
  methods: {
    init() {
      let { integralGoodsId } = this.$route.query || {};
      this.integralGoodsId = integralGoodsId;

      let p1 = getPayIntegralGoodsInfo({ integralGoodsId }).then(res => {
        this.orderInfo = res || {};
        if (this.isNeedMoneyToPay && this.orderInfo.integralUsed) {
          this.form.payWay = '06';
        } else if (this.isNeedMoneyToPay) {
          this.form.payWay = '01';
        } else {
          this.form.payWay = '05';
        }
      });

      Promise.all([p1]).then(() => {
        this.loading = false;
      });
    },
    handleAddressChange(address) {
      if (this.$route.name !== 'integralPayment') return;

      let { provinceCode } = address;
      // 商品id参数设置
      if (provinceCode) {
        // 邮费查询
        let { integralGoodsId } = this.$route.query || {};
        getPayIntegralGoodsInfo({ integralGoodsId }).then(res => {
          let { attribute, areas, carriage } = res || {};
          // console.log({
          //   attribute, areas, carriage, provinceCode
          // });
          areas = areas || '';
          carriage = +carriage || 0;

          let bu_bao_you = attribute === 1; // 不包邮
          let bao_you = attribute === 2; // 包邮
          let bu_fen_bao_you = attribute === 3; // 部分地区包邮
          if (bu_bao_you) {
            this.form.carriage = carriage;
          } else if (bao_you) {
            this.form.carriage = 0;
          } else if (bu_fen_bao_you) {
            this.form.carriage = areas.indexOf(provinceCode) < 0 ? carriage : 0;
          }
        });
      } else {
        this.form.carriage = 0;
      }
    },
    getFirstImg(goods) {
      return objectGetter(goods, 'imageList.0.url');
    },
    formatPrice(price, digits = 10) {
      return parseFloat(price.toFixed(digits));
    },

    // 提交订单
    saveOrder() {
      if (this.loading || this.addressLoading)
        return console.log('loading~~');

      let { orderInfo, addressInfo, form, displayAllPrice } = this;

      if (!addressInfo.name) {
        Dialog.alert({
          message: `请先完善收货地址，再购买商品`,
          confirmButtonText: '去完善',
        }).then(() => {
          this.$router.push('/addressList?model=check');
        });
        return;
      }

      let otherForm = {
        amount: displayAllPrice.goodsPrice,
        integralUsed: displayAllPrice.integralUsed,
        revicerProvinceCode: addressInfo.provinceCode,
        revicerProvince: addressInfo.provice,
        revicerCityCode: addressInfo.cityCode,
        revicerCity: addressInfo.city,
        revicerCountryCode: addressInfo.countryCode,
        revicerCountry: addressInfo.country,
        revicerStreetCode: addressInfo.streetCode,
        revicerStreet: addressInfo.street,
        revicerAddress: addressInfo.address,
        revicerMobile: addressInfo.mobile,
        revicerName: addressInfo.name,
      };

      let toast = Toast.loading({
        message: '创建订单中...',
        forbidClick: true, // 禁用背景点击
      });

      integralSaveOrder({
        ...orderInfo,
        ...form,
        ...otherForm,
      }).then(order => {
        if (order && order.id) {
          toast.clear();
          // 微信支付
          if (this.isNeedMoneyToPay) {
            wxJsPay(order, 'integralPayment').then(res => {
              let isPaySuccess = res === true;
              if (isPaySuccess) {
                Toast('微信支付成功');
              }
              this.goOrderListPage(isPaySuccess);
            });
          } else {
            //只需要积分兑换
            Dialog.confirm({
              title: `是否确认积分支付`,
              // message: '弹窗内容',
              confirmButtonText: '确认',
              beforeClose: (action, done) => {
                // 点击确认兑换
                if (action === 'confirm') {
                  let toast = Toast.loading({
                    message: '积分支付中...',
                    forbidClick: true,
                  });

                  integralPayment({ id: order.id }).then(res => {
                    let isSuccess = objectGetter(res, 'result') === 'true';
                    if (isSuccess) {
                      Toast('积分支付成功');
                    }
                    done();
                    this.goOrderListPage(isSuccess);
                  });
                } else {
                  // 点击取消，页面back
                  this.goOrderListPage();
                  done();
                }
              },
            }).catch(() => {
            });
          }
        }
      });
    },

    goOrderListPage(isPaySuccess = false) {
      this.$store.commit('integralOrderList/setRefreshing', true);
      this.$router.replace({
        path: '/integralOrderList',
        query: {
          status: isPaySuccess ? '' : DAI_FU_KUAN,
        }
      });
    },

  },
};
</script>

<style lang="less" scoped>
.payment-container {
  padding: 0 0 calc(54px + constant(safe-area-inset-bottom)) 0;
  padding: 0 0 calc(54px + env(safe-area-inset-bottom)) 0;

  /deep/ .van-stepper__minus,
  /deep/ .van-stepper__plus {
    width: 20px;
    height: 20px;
  }

  .payment-card-item {
    margin: 10px;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
  }

  .goods-info-container {
    padding: 15px;

    .agency-container {
      display: flex;
      align-items: center;
      margin: 4px 0 14px 0;
      line-height: 1.2;
      font-size: 16px;
      color: #333;

      .van-icon {
        margin-right: 8px;
        font-size: 18px;
      }
    }
  }

  .pay-way-container {

    .van-icon {
      margin-right: 10px;
      font-size: 26px;
    }

    .points {
      color: @primary-color;

    }

    .wechat {
      color: #07C160;
    }

    .gold-coin-o {
      color: @primary-color;
    }

    .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
      border: none;
    }

    .van-cell__title {
      display: flex;
      align-items: center;
    }

    .user-balance-display-container {
      margin-left: 1em;
      font-size: 14px;
      color: #666;
    }
  }

  .payment-bottom-bar {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 constant(safe-area-inset-bottom) 0;
    padding: 0 0 env(safe-area-inset-bottom) 0;
    width: 100%;
    height: 54px;
    background-color: #fff;

    .total-container {
      display: flex;
      padding: 0 0 0 10px;
      height: 100%;

      & > span {
        display: flex;
        align-items: center;
      }

      .price-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .total-price {
          display: flex;
          align-items: center;
          line-height: 1.2;
          font-size: 14px;
          color: #000;

          & > span + span:before {
            content: '+';
            margin: 0 4px;
            font-size: 13px;
            font-weight: normal;
            color: #999;
          }

        }

        .carriage-price {
          display: flex;
          align-items: center;
          margin-left: 4px;
          line-height: 1;
          color: #666;
          font-size: 12px;

          & > span:before {
            content: '¥';
            font-size: 12px;
            font-weight: normal;
          }
        }
      }
    }

    .buy-container {
      padding: 0 10px 0 0;
      width: 88px;
      font-size: 14px;
      font-weight: bolder;

      .van-button {
        height: 38px;
      }
    }
  }

  /deep/ .goods-image {
    height: 0;
  }

  /deep/ .goods-info-item .goods-info-detail {
    height: auto;

    .goods-price {
      flex-direction: column;
      margin-top: 10px;
      line-height: 1.1;
    }
  }

  /deep/ .integral-price {
    font-size: 18px;
    font-weight: bolder;
    color: #e93b3d;

    &:before {
      content: '¥ ';
      font-size: 12px;
      font-weight: normal;
    }
  }

  /deep/ .integral {
    font-size: 18px;
    font-weight: bolder;
    color: #ffa800;

    &:after {
      content: '积分';
      margin-left: 3px;
      font-size: 12px;
      font-weight: normal;
    }
  }

  /deep/ .van-stepper.van-stepper--round {
    margin-top: 10px;
    text-align: right;
  }
}
</style>
